import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c60fd9ae = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "" */))
const _94eb9e18 = () => interopDefault(import('..\\pages\\home\\newHome_v2.vue' /* webpackChunkName: "" */))
const _a41f5588 = () => interopDefault(import('..\\pages\\eConsult\\combatClass' /* webpackChunkName: "" */))
const _661d64c8 = () => interopDefault(import('..\\pages\\aboutUs\\newIndex' /* webpackChunkName: "" */))
const _767b64e0 = () => interopDefault(import('..\\pages\\teachers\\index' /* webpackChunkName: "" */))
const _08c42749 = () => interopDefault(import('..\\pages\\aboutUs\\encyclopedias' /* webpackChunkName: "" */))
const _18196b5b = () => interopDefault(import('..\\pages\\aboutUs\\teachers' /* webpackChunkName: "" */))
const _5e198066 = () => interopDefault(import('..\\pages\\questionAnswer\\index' /* webpackChunkName: "" */))
const _b36336ec = () => interopDefault(import('..\\pages\\questionAnswer\\searchResult' /* webpackChunkName: "" */))
const _e32a9684 = () => interopDefault(import('..\\pages\\questionAnswer\\searchDetail' /* webpackChunkName: "" */))
const _39b6fb20 = () => interopDefault(import('..\\pages\\eServices\\index' /* webpackChunkName: "" */))
const _58b289ea = () => interopDefault(import('..\\pages\\eInformation\\index' /* webpackChunkName: "" */))
const _63a1b0e0 = () => interopDefault(import('..\\pages\\eInformation\\search' /* webpackChunkName: "" */))
const _96c24b8e = () => interopDefault(import('..\\pages\\eInformation\\detail' /* webpackChunkName: "" */))
const _5139357c = () => interopDefault(import('..\\pages\\studentColumn\\newIndex' /* webpackChunkName: "" */))
const _61d58ac2 = () => interopDefault(import('..\\pages\\eCircle\\index_new' /* webpackChunkName: "" */))
const _1142ecd2 = () => interopDefault(import('..\\pages\\studyTour\\index' /* webpackChunkName: "" */))
const _2fb1da8a = () => interopDefault(import('..\\pages\\eClub\\xcClub' /* webpackChunkName: "" */))
const _29dc1f20 = () => interopDefault(import('..\\pages\\eClub\\yyClub' /* webpackChunkName: "" */))
const _5f5d5cec = () => interopDefault(import('..\\pages\\eClub\\jdClub' /* webpackChunkName: "" */))
const _78fc888c = () => interopDefault(import('..\\pages\\eClub\\pddClub' /* webpackChunkName: "" */))
const _3e564694 = () => interopDefault(import('..\\pages\\eClub\\syClub' /* webpackChunkName: "" */))
const _4b1b937c = () => interopDefault(import('..\\pages\\eClub\\pbClub' /* webpackChunkName: "" */))
const _658a1180 = () => interopDefault(import('..\\pages\\eClub\\ymxClub' /* webpackChunkName: "" */))
const _7187a936 = () => interopDefault(import('..\\pages\\eClub\\dyClub' /* webpackChunkName: "" */))
const _27d5259f = () => interopDefault(import('..\\pages\\eClub\\xhsClub' /* webpackChunkName: "" */))
const _6d6c4a3a = () => interopDefault(import('..\\pages\\eClub\\aliClub' /* webpackChunkName: "" */))
const _6d9690bd = () => interopDefault(import('..\\pages\\eClub\\allClub' /* webpackChunkName: "" */))
const _a0b5025c = () => interopDefault(import('..\\pages\\eConsultation\\dgmCenter' /* webpackChunkName: "" */))
const _67d741af = () => interopDefault(import('..\\pages\\eClub\\zzyxClub' /* webpackChunkName: "" */))
const _02323ee4 = () => interopDefault(import('..\\pages\\eTrain\\index' /* webpackChunkName: "" */))
const _5f3cc280 = () => interopDefault(import('..\\pages\\eConsult\\eGlobal' /* webpackChunkName: "" */))
const _6a5d1975 = () => interopDefault(import('..\\pages\\eConsult\\eTaoBaoNew' /* webpackChunkName: "" */))
const _5bfeb7a2 = () => interopDefault(import('..\\pages\\eConsult\\tiktok' /* webpackChunkName: "" */))
const _6bf77371 = () => interopDefault(import('..\\pages\\eConsult\\hwtiktok' /* webpackChunkName: "" */))
const _21ff295d = () => interopDefault(import('..\\pages\\eConsult\\ali1688Class' /* webpackChunkName: "" */))
const _0949bd14 = () => interopDefault(import('..\\pages\\eConsult\\jdCombat' /* webpackChunkName: "" */))
const _705d405c = () => interopDefault(import('..\\pages\\eConsult\\pddCombat' /* webpackChunkName: "" */))
const _9a81f51c = () => interopDefault(import('..\\pages\\eConsult\\redBook' /* webpackChunkName: "" */))
const _6fac5c50 = () => interopDefault(import('..\\pages\\eConsult\\ymxCombat' /* webpackChunkName: "" */))
const _04c52a66 = () => interopDefault(import('..\\pages\\eConsult\\financeClass' /* webpackChunkName: "" */))
const _1e07064e = () => interopDefault(import('..\\pages\\eConsult\\brandClass' /* webpackChunkName: "" */))
const _6b256868 = () => interopDefault(import('..\\pages\\eConsult\\bjClass' /* webpackChunkName: "" */))
const _64fd3e49 = () => interopDefault(import('..\\pages\\eConsult\\growthClass' /* webpackChunkName: "" */))
const _02aaae4b = () => interopDefault(import('..\\pages\\industry\\jywyh_index' /* webpackChunkName: "" */))
const _5b8c0d8a = () => interopDefault(import('..\\pages\\eConsult\\discClass' /* webpackChunkName: "" */))
const _6e8606d0 = () => interopDefault(import('..\\pages\\eConsult\\ggClass' /* webpackChunkName: "" */))
const _fd6b8158 = () => interopDefault(import('..\\pages\\eConsult\\decisionClass' /* webpackChunkName: "" */))
const _2f979271 = () => interopDefault(import('..\\pages\\eConsult\\productClass' /* webpackChunkName: "" */))
const _17e6b0de = () => interopDefault(import('..\\pages\\eConsult\\ppbClass' /* webpackChunkName: "" */))
const _0a371b0b = () => interopDefault(import('..\\pages\\xcFan\\index' /* webpackChunkName: "" */))
const _64b655ea = () => interopDefault(import('..\\pages\\xcFan\\posList' /* webpackChunkName: "" */))
const _99c07148 = () => interopDefault(import('..\\pages\\xcFan\\ideaList' /* webpackChunkName: "" */))
const _c2c30f02 = () => interopDefault(import('..\\pages\\digitalIntel' /* webpackChunkName: "" */))
const _6ef5a53c = () => interopDefault(import('..\\pages\\capitalism' /* webpackChunkName: "" */))
const _26f8bd3f = () => interopDefault(import('..\\pages\\industry' /* webpackChunkName: "" */))
const _658d95fb = () => interopDefault(import('..\\pages\\industry\\xyh_index' /* webpackChunkName: "" */))
const _406a1b35 = () => interopDefault(import('..\\pages\\eConsult\\kfClass' /* webpackChunkName: "" */))
const _822bc216 = () => interopDefault(import('..\\pages\\openClass\\combat_3' /* webpackChunkName: "" */))
const _1e59c613 = () => interopDefault(import('..\\pages\\openClass\\combat_dy' /* webpackChunkName: "" */))
const _15760c39 = () => interopDefault(import('..\\pages\\eConsult\\privateDomainClass' /* webpackChunkName: "" */))
const _e2de26da = () => interopDefault(import('..\\pages\\eConsultation\\csClub' /* webpackChunkName: "" */))

const _7ffe344f = () => interopDefault(import('..\\pages\\404.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _c60fd9ae,
    redirect: "/home",
    name: "index",
    children: [{
      path: "/home",
      component: _94eb9e18,
      meta: {"activeMenu":"home"},
      name: "home"
    }, {
      path: "/combatClass",
      component: _a41f5588,
      meta: {"activeMenu":"combatClass"},
      name: "combatClass"
    }, {
      path: "/aboutUs",
      component: _661d64c8,
      meta: {"activeMenu":"aboutUs"},
      name: "aboutUs"
    }, {
      path: "/teacherTeam",
      component: _767b64e0,
      meta: {"activeMenu":"teacherTeam"},
      name: "teacherTeam"
    }, {
      path: "/encyclopedias",
      component: _08c42749,
      meta: {"activeMenu":"encyclopedias"},
      name: "encyclopedias"
    }, {
      path: "/teachers",
      component: _18196b5b,
      meta: {"activeMenu":"aboutUs"},
      name: "teachers"
    }, {
      path: "/questionAnswer",
      component: _5e198066,
      meta: {"activeMenu":"questionAnswer"},
      name: "questionAnswer"
    }, {
      path: "/searchResult",
      component: _b36336ec,
      meta: {"activeMenu":"questionAnswer"},
      name: "searchResult"
    }, {
      path: "/searchDetail",
      component: _e32a9684,
      meta: {"activeMenu":"questionAnswer"},
      name: "searchDetail"
    }, {
      path: "/eServices",
      component: _39b6fb20,
      meta: {"activeMenu":"eServices"},
      name: "eServices"
    }, {
      path: "/eInformation",
      component: _58b289ea,
      meta: {"activeMenu":"eInformation"},
      name: "eInformation"
    }, {
      path: "/eInformationSearch",
      component: _63a1b0e0,
      meta: {"activeMenu":"eInformation"},
      name: "eInformationSearch"
    }, {
      path: "/eInformationDetail",
      component: _96c24b8e,
      meta: {"activeMenu":"eInformation"},
      name: "eInformationDetail"
    }, {
      path: "/studentColumn",
      component: _5139357c,
      meta: {"activeMenu":"studentColumn"},
      name: "studentColumn"
    }, {
      path: "/eCircle",
      component: _61d58ac2,
      meta: {"activeMenu":"eCircle"},
      name: "eCircle"
    }, {
      path: "/studyTour",
      component: _1142ecd2,
      meta: {"activeMenu":"studyTour"},
      name: "studyTour"
    }, {
      path: "/eConsultation",
      component: _2fb1da8a,
      meta: {"activeMenu":"eConsultation"},
      name: "eConsultation"
    }, {
      path: "/billionClub",
      component: _29dc1f20,
      meta: {"activeMenu":"billionClub"},
      name: "billionClub"
    }, {
      path: "/jdClub",
      component: _5f5d5cec,
      meta: {"activeMenu":"jdClub"},
      name: "jdClub"
    }, {
      path: "/pddClub",
      component: _78fc888c,
      meta: {"activeMenu":"pddClub"},
      name: "pddClub"
    }, {
      path: "/syClub",
      component: _3e564694,
      meta: {"activeMenu":"syClub"},
      name: "syClub"
    }, {
      path: "/pbClub",
      component: _4b1b937c,
      meta: {"activeMenu":"pbClub"},
      name: "pbClub"
    }, {
      path: "/ymxClub",
      component: _658a1180,
      meta: {"activeMenu":"ymxClub"},
      name: "ymxClub"
    }, {
      path: "/tiktokClub",
      component: _7187a936,
      meta: {"activeMenu":"tiktokClub"},
      name: "tiktokClub"
    }, {
      path: "/redBookClub",
      component: _27d5259f,
      meta: {"activeMenu":"redBookClub"},
      name: "redBookClub"
    }, {
      path: "/aliClub",
      component: _6d6c4a3a,
      meta: {"activeMenu":"aliClub"},
      name: "aliClub"
    }, {
      path: "/allClub",
      component: _6d9690bd,
      meta: {"activeMenu":"allClub"},
      name: "allClub"
    }, {
      path: "/dgmCenter",
      component: _a0b5025c,
      meta: {"activeMenu":"dgmCenter"},
      name: "dgmCenter"
    }, {
      path: "/researchCenter",
      component: _67d741af,
      meta: {"activeMenu":"researchCenter"},
      name: "researchCenter"
    }, {
      path: "/eTrain",
      component: _02323ee4,
      meta: {"activeMenu":"eTrain"},
      name: "eTrain"
    }, {
      path: "/eGlobal",
      component: _5f3cc280,
      meta: {"activeMenu":"eGlobal"},
      name: "eGlobal"
    }, {
      path: "/eTaoBao",
      component: _6a5d1975,
      meta: {"activeMenu":"eTaoBao"},
      name: "eTaoBao"
    }, {
      path: "/tiktok",
      component: _5bfeb7a2,
      meta: {"activeMenu":"tikTok"},
      name: "tikTok"
    }, {
      path: "/hwtiktok",
      component: _6bf77371,
      meta: {"activeMenu":"hwtiktok"},
      name: "hwtiktok"
    }, {
      path: "/ali1688Class",
      component: _21ff295d,
      meta: {"activeMenu":"ali1688Class"},
      name: "ali1688Class"
    }, {
      path: "/jdCombat",
      component: _0949bd14,
      meta: {"activeMenu":"jdCombat"},
      name: "jdCombat"
    }, {
      path: "/pddCombat",
      component: _705d405c,
      meta: {"activeMenu":"pddCombat"},
      name: "pddCombat"
    }, {
      path: "/redBook",
      component: _9a81f51c,
      meta: {"activeMenu":"redBook"},
      name: "redBook"
    }, {
      path: "/ymxCombat",
      component: _6fac5c50,
      meta: {"activeMenu":"ymxCombat"},
      name: "ymxCombat"
    }, {
      path: "/financeClass",
      component: _04c52a66,
      meta: {"activeMenu":"financeClass"},
      name: "financeClass"
    }, {
      path: "/brandClass",
      component: _1e07064e,
      meta: {"activeMenu":"brandClass"},
      name: "brandClass"
    }, {
      path: "/bjClass",
      component: _6b256868,
      meta: {"activeMenu":"bjClass"},
      name: "bjClass"
    }, {
      path: "/growthClass",
      component: _64fd3e49,
      meta: {"activeMenu":"growthClass"},
      name: "growthClass"
    }, {
      path: "/jywyh",
      component: _02aaae4b,
      meta: {"activeMenu":"jywyh"},
      name: "jywyh"
    }, {
      path: "/discClass",
      component: _5b8c0d8a,
      meta: {"activeMenu":"discClass"},
      name: "discClass"
    }, {
      path: "/performanceClass",
      component: _6e8606d0,
      meta: {"activeMenu":"performanceClass"},
      name: "performanceClass"
    }, {
      path: "/decisionClass",
      component: _fd6b8158,
      meta: {"activeMenu":"decisionClass"},
      name: "decisionClass"
    }, {
      path: "/productClass",
      component: _2f979271,
      meta: {"activeMenu":"productClass"},
      name: "productClass"
    }, {
      path: "/ppbClass",
      component: _17e6b0de,
      meta: {"activeMenu":"ppbClass"},
      name: "ppbClass"
    }, {
      path: "/xcFan",
      component: _0a371b0b,
      meta: {"activeMenu":"xcFan"},
      name: "xcFan"
    }, {
      path: "/posList",
      component: _64b655ea,
      meta: {"activeMenu":"xcFan"},
      name: "posList"
    }, {
      path: "/ideaList",
      component: _99c07148,
      meta: {"activeMenu":"xcFan"},
      name: "ideaList"
    }, {
      path: "/digitalIntel",
      component: _c2c30f02,
      meta: {"activeMenu":"digitalIntel"},
      name: "digitalIntel"
    }, {
      path: "/capitalism",
      component: _6ef5a53c,
      meta: {"activeMenu":"capitalism"},
      name: "capitalism"
    }, {
      path: "/industry",
      component: _26f8bd3f,
      meta: {"activeMenu":"industry"},
      name: "industry"
    }, {
      path: "/xyh",
      component: _658d95fb,
      meta: {"activeMenu":"xyh"},
      name: "xyh"
    }, {
      path: "/kfClass",
      component: _406a1b35,
      meta: {"activeMenu":"kfClass"},
      name: "kfClass"
    }, {
      path: "/combat_3",
      component: _822bc216,
      meta: {"activeMenu":"combat3"},
      name: "combat3"
    }, {
      path: "/combat_dy",
      component: _1e59c613,
      meta: {"activeMenu":"combat_dy"},
      name: "combatdy"
    }, {
      path: "/privateDomainClass",
      component: _15760c39,
      meta: {"activeMenu":"privateDomainClass"},
      name: "privateDomainClass"
    }, {
      path: "/csClub",
      component: _e2de26da,
      meta: {"activeMenu":"csClub"},
      name: "csClub"
    }]
  }, {
    path: "/*",
    redirect: "/home"
  }, {
    path: "/404",
    component: _7ffe344f,
    name: "404"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
